.profileImageDiv {
    cursor: pointer;
    /* border: 2px solid rgba(114, 37, 38, 0.9); */
    border-radius: 50%;
    width: 205px !important;
    height: 205px !important;
  }
  .profileImageDiv > img {
    max-width: 205px;
    max-height: 205px;
  }
  .profileInfoDiv > h2 {
    color: #772e30;
    font-family: var(--opensans-bold) !important;
  }
  .profileImageUploadButtonDiv {
    position: absolute;
    cursor: pointer;
    /* bottom: 0; */
    height: 205px;
    width: 205px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 50%;
    border: solid 2px rgb(226 226 226 / 90%);
    overflow: hidden;
  }
  .profileImageUploadButton {
    background: rgb(0 0 0 / 18%);
    flex: 1 1;
    height: 50px;
    /* padding-top: 15px; */
    font-size: 17px;
    font-weight: 600;
    color: rgba(114, 37, 38, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detailText {
    font-size: 19px;
    font-weight: 400;
    color: #722526;
  }
  .changePwd {
    float: right;
    color: #772e30;
  }
  
  
  /*--added 20/05/21---*/
  .profileInfoRow{
    transform: translateY(0px);  
    position: relative;
    bottom: -30px;
    z-index: 1;
  }
  .profileImageDiv{
    margin: auto;
  }
  .profileUpdateFormDiv{
    box-shadow: 1px 1px 20px 2px rgb(0 0 0 / 9%);
    padding: 3rem;
    border-radius: 10px;
    background: #fff;
    border: 0;
  }
  
  @media (max-width:767px){
    .profileInfoRow{
      padding: 2rem 0;
      bottom: 0 !important;
    }
    .profileInfoDiv > h2 {
      padding: 2rem 0 0;
      text-align: center;
    }
    .profileUpdateFormDiv{
      padding: 3rem 1rem;
    }
  }