@import "../constants/fonts/fonts.css";
.headerCoverMainDiv {
  /* margin-bottom: 5rem; */
}
.headerCoverDiv {
  /* background-image: linear-gradient(
      0deg,
      rgba(190, 104, 77, 0.75),
      rgba(190, 104, 77, 0.75)
    ),
    url(homeHeaderCover.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.headerCoverHeaderDiv {
  min-height: 60vh;
}
.headerCoverHeading {
  color: #fff;
  font-size: 45px;
  font-weight: 600;
  text-transform: uppercase;
}
.headerCoverLabel {
  color: #fff;
  text-transform: uppercase;
  font-family: var(--opensans-bold) !important;
  font-size: 25px;
}
.headerCoverLabel1 {
  color: #fff;
  text-transform: uppercase;
  /* font-family: var(--opensans-bold) !important;
  font-size: 25px; */
}
.headerCoverSearchDiv {
  min-width: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 9px 14px 9px 16px;
  border-radius: 30px;
  border: solid 1px #fff;
}
.headerCoverSearchInputField {
  flex: 1;
  background-color: transparent;
  border: none;
}
.headerCoverSearchInputField:focus {
  outline: none;
}
.headerCoverSearchButton {
  background-color: #f1af43;
  padding: 7px;
  border-radius: 50%;
}
.headerCoverRegisterButton {
  width: 139px;
  height: 36px;
  text-align: center;
  background-color: #f1af43;
  color: #722526;
  border-radius: 20px;
  padding-top: 6px;
}
