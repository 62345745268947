/* @import "./fonts.css"; */
/*Universal Start*/

.noMarginBottom {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--opensans-regular);
}
p,
label,
div,
a {
  font-family: var(--opensans-light);
}
.mt-6 {
  margin-top: 4rem;
}
/* .headerCoverMainDiv {
  margin-bottom: 5rem;
} */
/* .headerCoverDiv {
  background-image: linear-gradient(
      0deg,
      rgba(6, 21, 76, 0.7),
      rgba(6, 21, 76, 0.7)
    ),
    url(../img/ourservices/ourServicesHeader.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} */
/* .headerCoverHeaderDiv {
  min-height: 450px;
} */
/* .headerCoverHeading {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
} */
.searchbarComponentMainDiv {
  border: solid 1px #ccc;
  padding: 12px 20px;
  border-radius: 15px;
}

.searchbarComponentMainDiv > div:nth-child(1) {
  flex: 1;
}
.searchbarComponentMainDiv > div:nth-child(1) > input {
  border: none;
  min-width: 100%;
  font-size: 20px;
}
.searchbarComponentMainDiv > div:nth-child(1) > input:focus {
  outline: none !important;
}
.filterSearchButton {
  border: none;
  background-color: transparent;
}
.filterSearchButton > i {
  color: #e63c49;
  font-size: 22px;
}
.blackFont {
  color: #000;
}
.darkBlueFont {
  color: #06154c;
}
/*Universal End*/
/*Login Page Start*/
.login-container {
  min-width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  margin-top: -16px;
}
.loginScreenRow1 {
  transform: translateY(30%);
}
.loginPageFormDiv {
  background-color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px;
}
.loginFacebookDiv {
  background-color: #3b5998;
  color: #fff;
  border-radius: 16px;
}
.loginGoogleDiv {
  background-color: #db3236;
  color: #fff;
  border-radius: 16px;
}
.loginEmail {
  background-color: #fff;
  color: #4267b2;
  border: solid 1px #4267b2;
  border-radius: 16px;
}
#orTitleDiv {
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 28px;
  position: relative;
}
#orTitleDiv::before {
  content: "";
  width: 100%;
  border-bottom: solid 1px #000;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}
.orTitle {
  background-color: #fff; /* Same as the parents Background */
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 5px 0 5px;
  color: #000;
  position: relative;
  font-family: calibri;
  font-weight: lighter;
  margin: 0;
}
/*Login Page End*/
/*Login Form Page Start*/
.loginFormInput {
  min-width: 100%;
  border: none;
  border-bottom: solid 1px #888;
}
.loginFormForgetPasswordLabel {
  border-bottom: solid 1px #888;
  font-size: 13px;
}
.loginFormSubmitButton {
  min-width: 100%;
  border: none;
  background-color: #ff6067;
  color: #fff;
  padding: 4px 0 4px 0;
  border-radius: 16px;
}
#orTitleDiv2 {
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 28px;
  position: relative;
}
#orTitleDiv2::before {
  content: "";
  width: 100%;
  border-bottom: solid 1px #000;
  position: absolute;
  left: 0;
  top: 55%;
  z-index: 1;
}
.orTitle2 {
  background-color: #fff; /* Same as the parents Background */
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 5px 0 5px;
  color: #000;
  position: relative;
  font-family: calibri;
  font-weight: lighter;
  margin: 0;
  font-size: 12px;
}
.facebookIcon {
  color: #3b5998;
}
.googleIcon {
  color: #db3236;
}
.joinCommunity {
  font-size: 13px;
}
.underLineText {
  text-decoration: underline;
}

/*Login Form Page End*/
/*Navbar Start*/
.logoText {
  color: #fff;
}
.headerDiv {
  background-color: rgb(114, 37, 38, 0.9);
}
.facebookHeaderIcon {
  color: #06154c;
  background-color: #fff;
  padding: 3px 7px;
  border-radius: 50%;
}
.linkedInHeaderIcon {
  color: #06154c;
  background-color: #fff;
  padding: 3px 5px;
  border-radius: 50%;
}
.twitterInHeaderIcon {
  color: #06154c;
  background-color: #fff;
  padding: 3px 5px;
  border-radius: 50%;
}
#navBarThirdRow {
  margin: 0;
}
/*--added 19/05/21---*/
.headerCoverDiv_overlay{
  position: absolute;
  background: rgb(0 0 0 / 18%);
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  padding-top: 10%;
}

.service-wrap{
  border-radius: 10px;
  box-shadow: 0px 1px 20px 2px rgb(0 0 0 / 20%);
  background-color: rgb(0 0 0 / 44%);
}
.service-imagebox{
  border-radius: 10px;
}
.service-content{
  padding-left: 2rem;
  border-radius: 10px;
}
.service-content h2{
  margin-bottom: 1.5rem;
}
.service-content p{
  line-height: 30px;
}
.service-main {
  padding: 2% 0 6%;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.howitworks-main h2, .section-header{
  font-size: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.howitworks-each{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 3rem;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: .5s all;
}
.howitworks-each:hover{
  transition: .5s all;
  transform: translate(0px, -10px);
}
.howitworks-in{
  background-color: transparent;
}
.howitworks-each p{
  font-size: 16px;
}
.howitworks-each h6{
  font-size: 20px;
}
.howitworks-each .step-count{
  font-size: 18px;
}
.c-btn-primary,.c-btn-contact{
  width: 200px;
  height: 55px;
  border-radius: 50px;
  line-height: 2.7;
  color: #fff;
  font-weight: 600;
  background-color: #722526;
}
.c-btn-primary:hover{
  color: #fff !important;
  background-color: #f1af43 !important;
}
.getStarted-btn{
  /* padding: 0 0 10%; */
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}
.footer-main{
  background: rgb(114, 37, 38, 0.9);
}
.footer-in{
  padding: 3% 0;
}
.copyright-txt{
  padding: 1rem 0;
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.footer-social{
  padding: 26px 0 0 0;
}
.footer-firstCol label{
  margin: 5px 0 0 0px;
}
.footer-social .each-icon:hover{
    width: 35px;
    height: 35px;
    background: #722526;
}
.footer-social .each-icon{
  width: 35px;
  height: 35px;
  background: #f1af43;
}
.footer-social .each-icon:hover i{
  color: #fff;
}
.footer-social .each-icon i{
  color: #fff;
  font-size: 18px;
}
.footer-secondCol a{
  color: #666666;
  padding-bottom: 10px;
}
.footer-secondCol h6{
  margin: 0 0 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.footer-thirdCol h6{
  margin: 26px 0 0 0;
}
.c-btn-contact{
  width: 150px;
  height: 50px;
  line-height: 2.2;
}
.c-btn-contact a{
  color: #fff !important;
  font-size: 18px;
}
.c-btn-contact:hover{
  background-color: #f1af43 !important;
}
.c-btn-contact a:hover{
  color: #fff !important;

}
.user-icon{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

@media only screen and (min-width: 769px) {
  #navBarThirdRow {
    margin: 0;
    padding: 0 40px;
  }
}
@media only screen and (max-width: 768px) {
  .navbarThirdRowContainer {
    position: fixed;
    min-width: 100vw;
    background-color: #7b2e2d;
    top: 0;
    z-index: 1;
  }
}
/*Navbar End*/
/*Home Page*/
.homePageCityImage {
  max-width: 100%;
}
.coldBrewHeading {
  font-weight: 600;
  color: #06154c;
  font-size: 60px;
}
.coldBrewSlogan {
  color: #06154c;
  font-size: 30px;
}
.coldBrewTagline {
  color: #5796f7;
  font-size: 23.3px;
}
.homePageVisionImage {
  max-width: 150px;
}
.ourVisionHeading {
  color: #06154c;
  font-family: serif;
  font-size: 31px;
  font-weight: 500;
}
.ourVisionPara {
  color: #000;
  font-family: serif;
}
.addonsCoreIcon {
  font-size: 30px;
  background-color: #e63c49;
  padding: 15px 10px;
  border-radius: 50%;
  color: #06154c;
}
.addonsAddonIcon {
  font-size: 30px;
  background-color: #e63c49;
  padding: 12px 10px;
  border-radius: 50%;
  color: #06154c;
}
.addonsAddonIconImage {
  font-size: 30px;
  background-color: #e63c49;
  padding: 21px 13px;
  border-radius: 50%;
  color: #06154c;
}
.addonsCoreIcon {
  font-size: 30px;
  background-color: #e63c49;
  padding: 15px 10px;
  border-radius: 50%;
  color: #06154c;
}
.addonsCoreIconImage {
  font-size: 30px;
  background-color: #e63c49;
  padding: 21px 22px;
  border-radius: 50%;
  color: #06154c;
}
.addonsCoreHeading {
  color: #06154c;
  font-family: serif;
  font-size: 23px;
  font-weight: 600;
}
.addonsBullets {
  color: #000;
  font-weight: 500;
  font-family: serif;
  font-size: 18px;
}
.industriesImages {
  max-width: 100%;
  width: 380px;
  height: 195px;
}
.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
  /* width: 540px; */
  cursor: pointer;
  height: 194px;
}
.image-box img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: 195px;
  transform: scale(1);
}
.industriesMainTile:hover img {
  transform: scale(1.1);
}
.image-box:hover img {
  transform: scale(1.1);
}
.industriesTitle {
  min-width: 100%;
  text-align: center;
  background-color: #06154c;
  color: #fff;
}
.industriesMainHeading {
  color: #06154c;
  font-family: serif;
  font-size: 31px;
  font-weight: 500;
}
.industriesMainPara {
  color: #5796f7;
  font-size: 16px;
}
.industriesTilesHeading {
  padding-top: 75px;
  padding-bottom: 75px;
}
.homePageMentorButton,
.homePageSignUpButton {
  background-color: #e63c49;
  color: #fff;
  min-width: 280px;
  padding: 29px 0;
  font-size: 26px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
/*Hove Sweep Right*/
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
[class^="hvr-"] {
  margin: 0.4em;
  padding: 1em;
  cursor: pointer;
  background: #e1e1e1;
  text-decoration: none;
  color: #666;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* p,
[class^="hvr-"] {
  font-family: "Roboto", sans-serif;
} */
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #06154c;
  border-radius: 10px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #06154c;
  border-radius: 10px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover,
.hvr-sweep-to-right:focus,
.hvr-sweep-to-right:active {
  color: white;
}
.hvr-sweep-to-right:hover:before,
.hvr-sweep-to-right:focus:before,
.hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
@media only screen and (max-width: 768px) {
  .firstComponentHomeContainer {
    margin-top: 130px !important;
  }
}
/*Home Page*/
/*Footer Start*/
/* .footerMainDiv {
  background-color: #06154c;
} */
.footerLogoText {
  color: #fff;
}
.footerNavLink {
  color: #fff;
}
.footerFacebookIcon {
  color: #06154c;
  background-color: #fff;
  padding: 6px 10px;
  border-radius: 50%;
}
.footerTwitterIcon {
  color: #06154c;
  background-color: #fff;
  padding: 6px 7px;
  border-radius: 50%;
}
.footerLinkedinIcon {
  color: #06154c;
  background-color: #fff;
  padding: 5px 8px;
  border-radius: 50%;
}
.footerLine {
  color: #fff;
  font-size: 12px;
}
/*Footer End*/

/*About Us Header*/
.aboutHeaderMainDiv {
  background-color: #06154c;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.headerImage {
  max-width: 100%;
  height: 480px;
  width: 530px;
  transform: translate(20px, -130px);
}
.aboutUsHeaderBackImageDiv {
  /* background-image: url(../img/about/aboutHeader1.jpeg); */
  min-height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: translateY(-100px);
}
.aboutUsMainHeading {
  color: #fff;
  font-size: 70px;
  font-weight: 600;
}
.aboutUsTagLine {
  color: #fff;
  font-size: 32px;
}
.aboutUsPara {
  font-style: italic;
  color: #fff;
  font-size: 15px;
}
.howItWorksImage {
  max-width: 100%;
}
.howItWorksHeading,
.ourMentorHeading,
.ourTeamHeading {
  color: #06154c;
}
.teamMemberName {
  color: #06154c;
  font-weight: 600;
  font-size: 31px;
}
.teamMemberLabel {
  color: #5796f7;
  font-size: 19px;
}
.teamMemberFacebook {
  color: #fff;
  background-color: #3b5998;
  padding: 6px 9px;
  border-radius: 50%;
}
.teamMemberTwitter {
  color: #fff;
  background-color: #00acee;
  padding: 6px 6px 6px 7px;
  border-radius: 50%;
}
.teamMemberLinkedin {
  color: #fff;
  background-color: #0e76a8;
  padding: 6px 6px 6px 7px;
  border-radius: 50%;
}
.ourMentorsMainDiv {
  background-color: #fdf1f2;
}
.mentorLogoDiv {
  box-shadow: 0px 0px 5px 2px #ccc;
  border-radius: 5px;
}
.logoImage {
  max-width: 100%;
}
/*About Us Header*/
/*Our Services*/
/* .ourServicesMainDiv {
  margin-bottom: 5rem;
  background-image: linear-gradient(
      0deg,
      rgba(6, 21, 76, 0.7),
      rgba(6, 21, 76, 0.7)
    ),
    url(../img/ourservices/ourServicesHeader.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ourServicesHeaderDiv {
  min-height: 450px;
}
.ourServicesHeading {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
} */
.careerNeedDescDiv {
  background-color: #06154c;
  color: #fff;
  width: 230px;
  padding: 8px;
  border-radius: 15px;
}
.careerNeedsHeading {
  color: #06154c;
  font-size: 28px;
}
.careerNeedsPara {
  color: #00acee;
  font-size: 13px;
  text-align: center;
  line-height: 1;
}
.coreGroupSessionHeading {
  color: #06154c;
  font-size: 28px;
  text-decoration: underline;
}
.groupSessionTagLine {
  color: #06154c;
  font-size: 22px;
}
.individualServicePara {
  font-size: 18px;
  color: #000;
}
.individualServiceLi {
  color: #06154c;
  font-size: 26px;
  font-weight: 500;
}
.individualServiceLi2 {
  color: #06154c;
  font-size: 22px;
  font-weight: 500;
}
.coreGroupSessionImage,
.indicudualServicesImage {
  max-width: 100%;
}
/*Our Services*/
/*Our Crew*/
.ourCrewImage {
  max-width: 100%;
}
.ourCompaniesHeading {
  color: #06154c;
}
.ourCompaniesTagLine {
  color: #0061f2;
}
.searchProcessMainDiv {
  background-color: #fdf1f2;
}
.ourDecisionsHeading {
  color: #06154c;
}
.bookASessionDiv {
  background-color: #e48f95;
  color: #fff;
  padding: 45px 20px;
}
.bookASessionDiv > h1 {
  font-size: 35px;
}
.bookNowDiv {
  background-color: #06154c;
  color: #fff;
  padding: 30px 10px;
}
/*Our Crew*/
/*Resources*/
.technologyCoverImageMainDiv {
  margin-bottom: 5rem;
  /* background-image: linear-gradient(
      0deg,
      rgba(6, 21, 76, 0.7),
      rgba(6, 21, 76, 0.7)
    ),
    url(../img/resources/technologyCover.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.technologyTileCoverImageDiv {
  /* margin-bottom: 5rem; */
  /* background-image: url(../img/resources/technology.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 175px;
}
.technologyCoverHeaderDiv {
  min-height: 200px;
}
.technologyCoverHeading {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
}
.technologyTileHeading {
  font-size: 26px;
  color: #000;
  font-weight: 600;
}
.technologyTileContentDiv {
  background-color: #f6dbdd;
}
.technologyTileContentDiv > ul > li {
  color: #000;
  font-size: 12px;
}
.technologyTileButton {
  flex: 12;
  background-color: #06154c;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
}
/**/
.financeCoverImageMainDiv {
  margin-bottom: 5rem;
  /* background-image: linear-gradient(
      0deg,
      rgba(6, 21, 76, 0.7),
      rgba(6, 21, 76, 0.7)
    ),
    url(../img/resources/financeCover.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.financeTileCoverImageDiv {
  /* margin-bottom: 5rem; */
  /* background-image: url(../img/resources/finance.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 175px;
}
.financeCoverHeaderDiv {
  min-height: 200px;
}
.financeCoverHeading {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
}
.financeTileHeading {
  font-size: 26px;
  color: #000;
  font-weight: 600;
}
.financeTileContentDiv {
  background-color: #f6dbdd;
}
.financeTileContentDiv > ul > li {
  color: #000;
  font-size: 12px;
}
.financeTileButton {
  flex: 12;
  background-color: #06154c;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
}
/**/
.accountingCoverImageMainDiv {
  margin-bottom: 5rem;
  /* background-image: linear-gradient(
      0deg,
      rgba(6, 21, 76, 0.7),
      rgba(6, 21, 76, 0.7)
    ),
    url(../img/resources/accountingCover.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.accountingTileCoverImageDiv {
  /* margin-bottom: 5rem; */
  /* background-image: url(../img/resources/accounting.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 175px;
}
.accountingCoverHeaderDiv {
  min-height: 200px;
}
.accountingCoverHeading {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
}
.accountingTileHeading {
  font-size: 26px;
  color: #000;
  font-weight: 600;
}
.accountingTileContentDiv {
  background-color: #f6dbdd;
}
.accountingTileContentDiv > ul > li {
  color: #000;
  font-size: 12px;
}
.accountingTileButton {
  flex: 12;
  background-color: #06154c;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
}
/**/
.healthCareCoverImageMainDiv {
  margin-bottom: 5rem;
  /* background-image: linear-gradient(
      0deg,
      rgba(6, 21, 76, 0.7),
      rgba(6, 21, 76, 0.7)
    ),
    url(../img/resources/healthCareCover.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.healthCareTileCoverImageDiv {
  /* margin-bottom: 5rem; */
  /* background-image: url(../img/resources/healthCare.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 175px;
}
.healthCareCoverHeaderDiv {
  min-height: 200px;
}
.healthCareCoverHeading {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
}
.healthCareTileHeading {
  font-size: 26px;
  color: #000;
  font-weight: 600;
}
.healthCareTileContentDiv {
  background-color: #f6dbdd;
}
.healthCareTileContentDiv > ul > li {
  color: #000;
  font-size: 12px;
}
.healthCareTileButton {
  flex: 12;
  background-color: #06154c;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
}
/*Resources*/
/*Community*/
.filterButtons {
  color: #fff;
  background-color: #06154c;
  padding: 7px 0;
  width: 110px;
  text-align: center;
  border-radius: 8px;
}
.communityClientsMainDiv {
  background-color: rgb(248, 198, 202);
}
.clientsLogoRow {
  padding: 15px 0 15px 0px;
}
.postTileCoverImageDiv {
  /* background-image: url(../img/community/engineering.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 172px;
}
.postTileContentDiv {
  background-color: #f6dbdd;
}
.postTileHeading {
  font-size: 26px;
  color: #000;
  font-weight: 600;
}
.postTileContentDiv > div > ul > li {
  color: #000;
  font-size: 12px;
}
.postTileButton {
  flex: 12;
  background-color: #06154c;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
}
.recentPostHeading {
  color: #06154c;
  font-family: serif;
  font-size: 31px;
  font-weight: 500;
}
.recentPostImage {
  max-width: 100%;
}
.recentPostTileMainDiv {
  background-color: #f6dbdd;
}
.recentPostTileHeading {
  font-size: 26px;
  color: #000;
  font-weight: 600;
}
.recentPostTagLine,
.recentPostPara {
  color: #06154c;
  font-size: 15px;
}
.recentPostComment {
  font-size: 12px;
  color: #da6e6e;
}
.recentPostReadMoreButton {
  color: #fff;
  background-color: #06154c;
  font-size: 11px;
  padding: 3px 5px;
  border-radius: 5px;
}
.paginationPageNo {
  border: solid 1px #ccc;
  padding: 0px 10px;
  border-right: none;
}
.paginationLastButton {
  background-color: #e63c49;
  color: #fff;
  padding: 3px 13px;
  border-radius: 5px;
}
.enterPageNoInput {
  width: 35px;
  border: none;
  background-color: #f6dbdd;
  text-align: center;
}
.enterPageNoInput:focus {
  outline: none !important;
}
/*Community*/
/*Community Post*/
.addCommentBoxMainDiv {
  background-color: #f6dbdd;
  padding: 20px 30px;
  border-radius: 25px;
}
.addCommentBoxDiv {
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 25px;
}
.submitCommentButton {
  background-color: #06154c;
  color: #fff;
  padding: 7px 0;
  border-radius: 10px;
  min-width: 150px;
  transform: translateY(18px);
}
.addCommentTextArea {
  resize: none;
  border: none;
  border-radius: 25px;
}
.addCommentTextArea:focus {
  box-shadow: none;
}
.postDetailRightDivHeading {
  font-size: 25px;
  font-weight: 600;
}
.postDetailRightDivContent {
  font-size: 13px;
  color: #7979e4;
}
.contactFormInputDiv {
  box-shadow: 0px 0px 5px 2px #ccc;
  border-radius: 5px;
}
.contactFormInputField {
  min-width: 100%;
  border: none;
  padding: 8px;
  resize: none;
}
.contactFormInputField:focus {
  outline: none !important;
}
.contactFormSubmitButton {
  background-color: #e63c49;
  color: #fff;
  padding: 5px 9px;
  border-radius: 5px;
}
/*Community Post*/
.tressNavItems:hover {
  color: #f1af43;
}





html, body{height:100%;}

.radius-btn {
  background-color: #722526;
  color: #f1af43 !important;
  transition: all 0.3s ease;
  border-radius: 25px;
  cursor: pointer;
  padding:8px 15px;
  display:inline-block;
}
.radius-btn:hover {
  color: #722526 !important;
  background-color: #f1af43;
}

.link-btn{
  color: rgb(142, 80, 81);
  display:inline-block; text-decoration:underline;
  cursor: pointer;
}
.link-btn:hover { 
  color: #f1af43;
}


.rds-input{border-radius:50px;}

.pr-15{padding-right:15px !important;}


.brwn-prgrs.progress .progress-bar{background-color:rgb(142, 80, 81); font-weight:900;}
.tltp-btn{background:transparent; width:22px; height:22px; border-radius:50%; font-size:12px; font-weight:500; color:#722526; border-color:#722526; padding:0;}
.tltp-btn:hover{background-color:#722526; color:#fff;} 
.tltp-btn.btn-secondary:not(:disabled):not(.disabled).active, .tltp-btn.btn-secondary:not(:disabled):not(.disabled):active, .tltp-btn .show > .btn-secondary.dropdown-toggle, .tltp-btn.btn-secondary.focus, .tltp-btn.btn-secondary:focus{background:#722526; border-color:#722526; }
.tooltip-inner{background:#722526; max-width:500px; width:100%; line-height:24px;}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before{border-top-color:#722526;}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before{border-bottom-color:#722526;}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before{border-right-color:#722526;}
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before{border-left-color:#722526;}

.profile-sidebar{border:1px solid #ccc; background:#f3f3f3; border-radius:35px; width:100%; padding:30px 0;}
.profile-sidebar ul{padding:0;}
.profile-sidebar ul li{list-style:none; margin:0 0 1px;}
.profile-sidebar ul li:hover, .profile-sidebar ul li.active{background:#722526;}
.profile-sidebar ul li:hover a, .profile-sidebar ul li.active a{color:#fff;}
.profile-sidebar ul li a{color:#722526; padding:10px; display:block;}

.loadmore-btn-sec{display:block; width:100%; text-align:center;}

.not-found-page{padding:200px 15px; text-align:center;}
.not-found-page .title1{font-size:200px; font-weight:600; color:#722526;}
.not-found-page .title5{font-size:50px;}

.FirstQuestion_styleWrapperDiv__11IBM .tltp-btn{margin-top:1rem;}

.ThirdQuestion_styleWrapperDiv__3tXs7 .ThirdQuestion_hairImage__FsaAb{flex:1; width:1%;}
.ThirdQuestion_selectOverlay__3xyM0{left:-26px; right:0; margin:auto; top:-5px;}
.SecQuestion_selectOverlay__2ChZW{width:160px !important;}
.FourthQuestion_hairStyleDiv__202PW .tltp-btn{margin-top:1rem;}
.FifthQuestion_hairStyleDiv__9HcYR .tltp-btn{margin-top:1rem;}

.like-area{padding:0; display:flex; align-items:center}
.like-area li{list-style:none; margin:0 15px 0 0;}
.like-area li i{color:#a7a7a7; font-size:18px;}
.like-area li i:hover, .like-area li.active i{color:#722526;}

.RecommendedResults_headerCoverHeading__1ZWyG{font-size:55px !important}
.HowItWorks_howItWorksPara__3-NOz{font-size:14px;}

#text-show-less {
  position:relative;
}
#text-show-less .text {
  display: block;
}
#text-show-less .show-more {
  color: rgb(142, 80, 81);
  font-weight: 600;
  display:inline-block; text-decoration:underline;
  cursor: pointer;
}
#text-show-less .show-more:hover { 
  color: #f1af43;
}
#text-show-less .show-more-height { 
  height:125px; 
  overflow:hidden; 
}


.popover{max-width:300px; width:100%; -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.18);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.18);
  -ms-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.18);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.18);
  }

.subpage-headcover h1{
  background: transparent;
}

.subpage-in h1{
  margin-bottom: 2rem;
}
.signup-in{
  background: #fff;
  box-shadow: 0px 0px 20px 2px rgb(0 0 0 / 9%);
  padding:1rem;
  border-radius: 10px;
  height: 100%;
}
.login-left {
  background: url("/assets/img/relevant/login-bg.jpg") no-repeat center;
  background-size: cover;
  height: 100%;
}
.login-left img{
  width: 90%;
}
.swal2-styled{
  width: 145px;
  height: 45px;
  border-radius: 50px !important;
  line-height: 1;
  color: #fff;
  font-weight: 600;
  background-color: #722526 !important;
}
.swal2-styled:hover{
  background-color: #f1af43 !important;
}
.profile-sidebar{
  position: relative;
  z-index: 0;
  box-shadow: 1px 1px 20px 2px rgb(0 0 0 / 9%);
  padding: 2rem;
  border-radius: 10px;
  background: #fff;
  border: 0;
  padding-top: 20%;
}
.profile-sidebar ul li{
  margin-bottom: .8rem;
}
.profile-sidebar ul li:hover, .profile-sidebar ul li.active{
  background: #722526;
  border-radius: 0 30px 30px 0px;
}
.hair-overview,.wishlist-main{
  background: #fff;
  box-shadow: 1px 1px 20px 2px rgb(0 0 0 / 9%);
  padding: 2rem;
  border-radius: 10px;
}
.loadmore-btn-sec .c-btn-primary{
  color: #fff !important;
}
.craft-head{
  width: 80%;
}

.craft-head input{
  color: #000;
}
.craft-head input::placeholder{color: #000;}
.subpage-headcover{
  padding-top: 8%;
}
.subpage-headcover label{
  background: none;
}
.h-c-label{
  margin: 1rem 0 !important;
}
.progress-main{
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 30px;
  padding: 10px;
  box-shadow: 3px 5px 17px 5px rgb(0 0 0 / 9%);
}
.progress-main .progress{
  width: 100%;
  border-radius: 30px;
  height: 25px;
  background: rgb(233 233 233 / 75%);
}
.question-in-main .question-in{
  border: 0;
  padding: 3%;
  margin-top: 2%;
}
.question-in-main .question-in h2{
  margin-bottom: 3rem;
}
.question-in-main .q-nav-btn{
  margin: 2rem 0;
}
.question-in-main .q-nav-btn label{
  text-align: center;
  padding: 10px 20px;
  margin: 0 5rem;
  color: #fff;
}
.question-in-main .q-nav-btn label:hover{
  opacity: .8;
  cursor: pointer;
}
.question-in-main .q-nav-btn .back-btn{
  background: rgb(122 122 122 / 77%);
  color: #fff;
}
.user-details input{
    border-radius: 30px;
    padding: 20px 15px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    border: 1px solid #d6d6d6;
}
.user-details label{
    margin: 0 0 6px 0;
    color: #000;
    font-size: 14px;
}
.profile-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 3rem;
}
.profile-title h2{
  margin: 0;
  line-height: normal;
}
.profile-title button{
  padding: 6px 28px;
}
.c-poppover{
  padding: 10px;
}
.c-poppover textarea.form-control{
  margin-bottom: 1rem;
  height: 80px;
}
.c-poppover .radius-btn{
  color: #fff !important;
}
.popover-btn{
  justify-content: space-between;
  width: 100%;
}
.profile-sub-in{
  margin-bottom: 0 !important;
}
.goto-home{
  color: #722526;
  margin: 0 0 5px;
  display: inline-block;
}
.goto-home:hover{
  color: #000;
}
.btn-explore{
  border-radius: 30px !important;
  color: #fff !important;
  margin-bottom: 5rem;
}
.theProductImage{
  border: 1px solid rgb(0 0 0 / 11%) !important;
}
.make-wish {
  position: absolute;
  width: 22px;
  top: 7px;
  right: 25px;
  height: 22px;
  background: rgb(0 0 0 / 20%);
  border-radius: 50%;
  padding: 2px;
}
.make-wish img{
  filter: gray; /* IE6-9 */
  -webkit-filter: brightness(0) invert(1); /* Chrome 19+ & Safari 6+ */
  /* box-shadow: 1px 2px 20px 4px rgb(0 0 0 / 19%); */
  padding: 2px;
}
.make-wish img:hover,.make-wish img.active,.make-wish a.active img{
  filter: none;
  -webkit-filter: brightness(1) invert(0);
}
.copyright-txt{
  display: flex;
  justify-content: space-between;
  padding: 1rem 15px; 
}
.copyright-txt ul{
  display: flex;
}
.copyright-txt ul li{
  list-style: none;
  padding-right:2rem;
  font-size: 14px;
}
.copyright-txt ul a{
  color: #fff;
}
.copyright-txt ul a:hover{
  opacity: .8;
}
.upload-label{
  color: #fff;
  font-size: 14px;
  margin: -5px 0 0 0px;
}
.upload-sec img{
  filter: white;
  -webkit-filter: brightness(0) invert(1);
  margin: -5px 0 0 0px;
}
#popover-content h5{
  color: #000;
}
/* .hair-p-o-content{
  text-align: left !important;
} */
.hair-p-o-content p{
  font-size: 20px;
  font-weight: 600;
  /* text-align: left; */
  margin: 30px 0 15px 0;
  color: #BF6849;
}
.fouth-img{
  width: 65%;
}
.like-subpage{
  padding: 10px 0 0;
}
.make-wish:hover{
  cursor: pointer;
}
.SecQuestion_selectOverlay__2ChZW {
  width: 100% !important;
}
.SecQuestion_styleWrapperDiv__33eEy{
  position: relative;
}
.tltp-btn{
  min-width: 22px;
  z-index: 1;
}
.wishlist-products h2{
  font-size: 18px;
}
.wishlist-products .wishlist-main p{
  font-size: 14px !important;
}
.wishlist-products .wishlist-main{
  padding: 2rem 1rem 2rem 2rem;
}
.d-pl-0{
  padding-left: 0 !important;
}
.pro-cat{
  display: block;
  color: #7e393c;
  margin: 0px 0 15px;
}
.hair-overview{
  text-align: center
}
.pro-image-wrap{
  border-radius: 5px !important;
  border: 1px solid rgb(0 0 0 / 11%);
}
.only-heading .subpage-headcover{
  padding-top: 5%;
}
.about-us-wraper .AboutUs_headerCoverDiv__2-m2L{
  /* background: #e1ad01 !important; */
  background: #fff !important;

}
.about-in-image{
  height: 100%;
  margin: 1% auto;
  text-align: center;
  /* margin-top: 5%; */
}
.about-in-image img{
  height: 100%;
  margin: auto;
  width: 50%;
  object-fit: cover;
}
.ads-wrap{
  text-align: center;
  margin: auto;
  margin-top: 2rem;
}


  @media (max-width:1024px){
    .service-content h2 {
      margin-bottom: 0.5rem;
    }
    .service-content p {
      line-height: 24px;
    }
    .headerCoverDiv_overlay{
      padding-top: 30%;
    }
    .profile-sidebar{
      padding-top: 11%;
    }
    .CraftProducts_headerCoverHeaderDiv__1QuxR ,.Products_headerCoverHeaderDiv__uMWgH,.subpage-headcover{
      min-height: 40vh !important;
      padding-top: 12% !important;
  }
  #mobile-nav ul li a{
    padding: 10px 22px 22px 15px;
  }
  .copyright-txt {
    display: block;
  }
  .copyright-txt ul{
    justify-content: center;
  }
  .copyright-txt ul li{
    padding: 5px;
  }
  .copyright-txt p{
    padding: 10px 0 0;
  }
  .SecQuestion_hairImage__31ZWM{
    width: 100% !important;
  }
  .FourthQuestion_styleWrapperDiv__Y4Qr5{
    padding: 10px !important;
  }
  .FifthQuestion_styleWrapperDiv__3PX_X{
    position: relative;
  }
  .FifthQuestion_selectOverlay__iZtTE{
    width: 90% !important;
    height: 100% !important;
  }
  .tltp-btn{
    min-width: 22px;
    z-index: 1;
  }
  .FifthQuestion_optionTitle__2Y4rq{
    word-break: break-word;
  }
  .ThirdQuestion_styleWrapperDiv__3tXs7 {
    padding: 10px 20px;
  }
  }

  @media (max-width:767px){
    .not-found-page{padding:120px 15px 80px}
    .not-found-page .title1{font-size:100px}
    .not-found-page .title5{font-size:30px;}
    .headerCoverDiv_overlay{
      padding-top: 30%;
    }
    .headerCoverLabel{
      font-size: 13px;
    }
    .howitworks-main h2{
      font-size: 1.8rem;
    }
    .footer-secondCol{
      margin: 1rem 0;
    }
    
    .profile-sidebar{
      padding: 15px;
      margin-bottom: 15px;
    }
    .craft-head {
      width: 100%;
  }
  .subpage-headcover label{
    text-align: center;
    font-size: 18px;
  }
  .subpage-headcover label:last-child{
    font-size: 16px;
    margin: 0 !important;
  }
  .subpage-headcover {
    padding-top: 20%;
  }

  .service-content h2 {
    font-size: 25px;
  }
  .service-content p {
    line-height: 24px;
    font-size: 14px;
  }
  .howitworks-in{
    padding-top: 2rem!important;
    padding-bottom: 0rem !important;
    margin-bottom: 0 !important;

  }
  .howitworks-main h2, .section-header{
    margin-bottom: 2rem;
    font-size: 25px;
  }
  .howitworks-each{
    padding: 1rem;
    width: 80%;
    margin: 0 auto;
  }
  .howitworks-each p{
    max-width: 100%;
  }
  .c-btn-primary, .c-btn-contact{
    padding: 2px 20px;
    font-size: 14px;
    width: auto;
    height: auto;
  }
  .c-btn-contact a{
    font-size: 14px;
  }
  .HeaderCover_headerCoverHeading__Gdmr5{
    line-height: normal;
  }
.CraftProducts_headerCoverHeading__302Ym,.subpage-headcover h1{
  font-size: 25px !important;
}
.subpage-search input{
  font-size: 13px;
}
.subpage-search {
  padding: 5px 23px !important;
  margin:1.8rem 0  0!important;
}
.hair-overview, .wishlist-main{
  padding-bottom: 0;
}
.subpage-headcover-main {
  margin-bottom: 0rem !important;
}
.quiz-main{
  margin-top: 3rem;
}
.howitworks-each img{
  width: 50px;
}
.question-in-main .question-in{
  padding: 20px;
}
.question-in-main .question-in h2{
  font-size: 20px;
}
.question-in-main .q-nav-btn label{
  margin: 10px 10px;
  width: 120px;
}
.SecQuestion_selectOverlay__2ChZW {
  height: 100% !important;
}
.ThirdQuestion_selectOverlay__3xyM0{
  /* height: 1 !important;
  width: 105px !important; */
}
.FifthQuestion_styleWrapperDiv__3PX_X{
  width: 100%;
}

.Profile_submitButton__1jn6H{
  padding: 10px 28px !important;
  text-transform: capitalize;
}
.Login_loginFormContainer__1zuyT h1{
  font-size: 26px !important;
}
.home-header,.home-header .subpage-search{
  min-height: 100% !important;
}
.home-header-wrap{
  min-height: 70vh !important;
}
.headerCoverDiv_overlay {
  padding-top: 25%;
}
.progress-main{
  margin: 1rem;
}
.quiz-main h1{
  font-size: 25px;
}
.Profile_profileInfoDiv__2N9_k > h2{
  font-size: 25px;
}
.Login_loginFormMainContainer__1xSJy{
  align-items: flex-start !important;
  padding: 2rem 0;
}
.copyright-txt ul {
  display: block;
  text-align: left;
}
.copyright-txt ul li{
  padding: 0 0 10px;
}
.copyright-txt p{
  text-align: left;
}
.CraftProducts_headerCoverHeaderDiv__1QuxR ,.Products_headerCoverHeaderDiv__uMWgH,.subpage-headcover{
  min-height: 40vh !important;
  padding-top: 20% !important;
}
.FirstQuestion_styleWrapperDiv__11IBM{
  width: 100%;
}
.FourthQuestion_selectOverlay__3g4lP{
  width: 90%;
}
.ThirdQuestion_styleWrapperDiv__3tXs7,.FourthQuestion_styleWrapperDiv__Y4Qr5{
  position: relative;
}
.ThirdQuestion_styleWrapperDiv__3tXs7{
  padding: 10px 10px;
}
.FifthQuestion_styleWrapperDiv__3PX_X {
  padding: 10px 10px !important;
}
.SecQuestion_styleWrapperDiv__33eEy{
  padding: 10px;
}
.d-pl-0{
  padding-left: 15px !important;
}
}


  @media (max-width:575px){
    .tooltip-inner{max-width:200px;}
    .CraftProducts_headerCoverHeading__302Ym, .subpage-headcover h1,.subpage-headcover label {
      padding: 0;
  }
  }


  /*** Remove this style after developing ***/
  .Products_theProductHeading__1YNXD {
    font-size: 20px;
    color: #772e30;
  }