@font-face {
  font-family: "Open-Sans-Regular";
  font-style: normal;
  src: url("./GothamMedium.ttf");
}
@font-face {
  font-family: "Open-Sans-Light";
  font-style: normal;
  src: url("./GothamLight.ttf");
}
@font-face {
  font-family: "Open-Sans-Bold";
  font-style: normal;
  src: url("./GothamBold.ttf");
}
:root {
  --opensans-regular: "Open-Sans-Regular";
  --opensans-light: "Open-Sans-Light";
  --opensans-bold: "Open-Sans-Bold";
}
