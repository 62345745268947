.mainHeading {
  color: rgb(114, 37, 38);
  font-weight: 600;
  font-size: 40px;
  text-align: center;
}
.subHeading {
  font-size: 25px;
  font-weight: 500;
  color: #888;
}
.para {
 
  font-size: 16px;
  color: #000;
  text-align: center;
}
.headerCoverMainDiv {
  margin-bottom: 5rem;
  position: relative;
}
.headerCoverDiv {

  background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

 
}

.headerCoverHeaderDiv {
  min-height: 60vh;
}
.headerCoverHeading {
  /* color: #fff; */
  color: #732424;
  font-size: 45px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.5;
  padding: 0 25px;
  border-radius: 35px;
}

.headerCoverLabel {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 25px;
  margin-right: auto;
  letter-spacing: 5px;
}
.headerCoverSearchDiv {
  min-width: 50%;
  background-color:rgb(255 255 255 / 91%);
  padding: 3px 12px 3px 16px;
  border-radius: 30px;
  border: solid 1px #fff;
  padding: 10px 23px;
  margin-top: 9%;
  box-shadow: 3px 5px 17px 5px rgb(255 255 255 / 35%);
}

.headerCoverSearchInputField {
  flex: 1;
  background-color: transparent;
  border: none;
  color: #000;

}
.headerCoverSearchInputField::placeholder {
  color: #000;
}
.headerCoverSearchInputField:focus {
  outline: none;
}
.headerCoverSearchButton {
  background-color: #f1af43;
  padding: 7px;
  border-radius: 50%;
}
.headerCoverRegisterButton {
  width: 139px;
  text-align: center;
  background-color: #f1af43;
  color: #722526;
  border-radius: 20px;
  padding: 8px 0;
  transition: all 0.3s ease;
  cursor: pointer;
}
.headerCoverRegisterButton:hover {
  color: #f1af43;
  background-color: #722526;
}
.headerSearchButton {
  background-color: transparent;
  border: none;
  transform: translate(13px, 0px);
}
.headerSearchButton:focus {
  outline: none;
}


@media only screen and (max-width: 1024px) {
  .headerCoverHeading {
    font-size: 1.8rem;
    margin-bottom: 1rem !important;
  }
  .headerCoverDiv_overlay{
    padding-top: 30%;
  }
  .headerCoverLabel{
    margin-right: initial;
  }
}
@media only screen and (max-width: 767px) {

}