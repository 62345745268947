@import "../../constants/fonts/fonts.css";
.question {
  color: #722526;
  /* font-family: serif; */
  font-weight: 100;
}
.questionContainer {
  background-color: #f6eae0;
  /* background-color: rgb(136, 97, 97); */
  border: solid 2px #888;
  border-radius: 30px;
  /* height: 724px; */
}
.selectOverlay {
  height: 125px;
  width: 125px;
  background-color: #000;
  border-radius: 10px;
  position: absolute;
  opacity: 0.5;
}
.selectOverlay > i {
  font-size: 35px;
  color: #fff;
}
.hairStyleDiv {
  cursor: pointer;
  position: relative;
}
.hairStyleDiv::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: transparent;
}
.styleWrapperDiv {
  padding: 10px 30px;
  transition: all 0.3s ease;
  border-radius: 15px;
}

.hairStyleDiv:hover .styleWrapperDiv {
  background-color: #fdefef;
}
.optionTitle {
  color: #8e5051;
  font-family: var(--opensans-bold) !important;
  cursor: pointer;
}
.nextButton {
  background-color: #722526;
  font-family: var(--opensans-bold) !important;
  color: #f1af43;
  width: 150px;
  padding: 4px 0;
  border-radius: 25px;
}
.image {
  box-shadow: 0px 0px 5px 2px #ccc;
  /* border: solid 1px #000; */
  border-radius: 10px;
}

