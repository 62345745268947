@import "../constants/fonts/fonts.css";

.getStartButton {
  /* font-family: var(--opensans-bold) !important; */
  background-color: #722526;
  color: #f1af43;
  width: 150px;
  /* padding: 9px 0 0 0; */
  transition: all 0.3s ease;
  border-radius: 25px;
  cursor: pointer;
  padding: 6px 0;
  /* height: 33px !important; */
}
.getStartTagLine {
  font-family: var(--opensans-bold) !important;
  color: #000;
  
}
.getStartButton:hover {
  color: #722526;
  background-color: #f1af43;
}
