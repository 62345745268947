@import "../constants/fonts/fonts.css";

.theProsImage {
  width: 153px;
  height: 100% !important;
  background-position: center;
  background-size: contain !important;
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 11%) !important;
}
.theProsPara {
  font-size: 23px;
  max-width: 510px;
  color: #000;
}
.theProsHeading {
  font-size: 20px;
  color: #772e30;
}
.filterDiv {
  background-color: #e9e9e9;
  min-height: 60vh;
  border-radius: 20px;
}

.filterItemMainDiv {
  padding: 10px 15px;
}
.filterItemDiv {
  border: solid 1px #ccc;
  padding: 12px 15px;
  border-radius: 25px;
  background-color: #fff;
  color: #000;
  font-weight: 500;
  font-family: var(--opensans-bold) !important;
}
.applyButton {
  background-color: #f1af43;
  color: #fff;
  text-align: center;
  min-width: 140px;
  padding: 6px 0;
  border-radius: 25px;
  font-family: var(--opensans-bold) !important;
}
.productMainDiv {
  background-color: #f2f2f2;
  padding: 0 10px;
  box-shadow: 0px 2px 5px 2px #ccc;
  border-radius: 15px;
  cursor: pointer;
}

.favouriteIconDiv {
  position: absolute;
  right: 25px;
  top: 5px;
}
.productLabel {
  color: #752d2e;
}
.productDesc {
  font-size: 10px;
  max-width: 200px;
}
.priceLabel {
  color: #752d2e;
  font-family: var(--opensans-bold) !important;
  font-size: 20px;
}
.grayHeart,
.grayCart {
  color: #ccc !important;
}
.redHeart {
  color: red !important;
}
.blackCart {
  color: #000 !important;
}
.heartIcon {
  cursor: pointer;
  color: #ccc;
}
.heartIcon:hover {
  color: red;
}
.cartIcon {
  color: #ccc;
  cursor: pointer;
}
.cartIcon:hover {
  color: #000;
}
.headerCoverSearchInputField {
  flex: 1;
  background-color: transparent;
  border: none;
}
.headerCoverSearchInputField::placeholder {
  color: #888;
}
.headerCoverSearchInputField:focus {
  outline: none;
}
.headerCoverSearchButton {
  background-color: #f1af43;
  padding: 7px;
  border-radius: 50%;
}
.theStudyDummyHeading {
  font-size: 20px;
  color: #772e30;
  background-color: rgb(119, 46, 48, 0.4);
  height: 30px;
  width: 240px;
}
.loadMoreButton {
  background-color: #722526;
  color: #f1af43;
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  transition: all 0.5s ease;
}
.loadMoreButton:hover {
  background-color: #f1af43;
  color: #722526;
}

.headerCoverMainDiv {
  margin-bottom: 5rem;
}
.headerCoverDiv {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.headerCoverHeaderDiv {
  min-height: 60vh;
}
.headerCoverHeading {
  color: #fff;
  font-size: 45px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  background-color: rgb(114, 37, 38, 0.6);
  padding: 0 25px;
}
.headerCoverLabel {
  color: #fff;
  text-transform: uppercase;
  font-family: var(--opensans-bold) !important;
  font-size: 25px;
  background-color: rgb(114, 37, 38, 0.6);
  padding: 0 25px;
}
.headerCoverLabel1 {
  color: #fff;
  text-transform: uppercase;
  background-color: rgb(114, 37, 38, 0.6);
  padding: 0 25px;
}
.headerCoverSearchDiv {
  min-width: 50%;
  background-color:rgb(255 255 255 / 91%);
  box-shadow: 3px 5px 17px 5px rgb(255 255 255 / 35%);
  padding: 10px 23px;
  border-radius: 30px;
  border: solid 1px #fff;
}
.headerCoverSearchInputField {
  flex: 1;
  background-color: transparent;
  border: none;
}

.headerCoverRegisterButton {
  width: 139px;
  height: 36px;
  text-align: center;
  background-color: #f1af43;
  color: #722526;
  border-radius: 20px;
  padding-top: 6px;
}
