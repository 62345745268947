@import "../constants/fonts/fonts.css";
.headerCoverMainDiv {
  margin-bottom: 5rem;
  position: relative;
}
.headerCoverDiv {
  /* background-image: linear-gradient(
      0deg,
      rgba(190, 104, 77, 0.75),
      rgba(190, 104, 77, 0.75)
    ),
    url(homeHeaderCover.png); */
  min-height: 100vh;
  background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

 
}

.headerCoverHeaderDiv {
  min-height: 60vh;
}
.headerCoverHeading {
  color: #fff;
  font-size: 4vw;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.5;
  /* background-color: rgb(114, 37, 38, 0.4); */
  padding: 0 25px;
  border-radius: 35px;
}

/* @media only screen and (min-width: 600px) {
  .headerCoverHeading {
    
    font-size: 35px;
    
  }
} */
.headerCoverLabel {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 25px;
  margin-right: auto;
  letter-spacing: 5px;
}
.headerCoverSearchDiv {
  min-width: 70%;
  background-color:rgb(255 255 255 / 91%);
  padding: 3px 12px 3px 16px;
  border-radius: 30px;
  border: solid 1px #fff;
  padding: 10px 23px;
  margin-top: 9%;
  box-shadow: 3px 5px 17px 5px rgb(255 255 255 / 35%);
}

.headerCoverSearchInputField {
  flex: 1;
  background-color: transparent;
  border: none;
  color: #000;

}
.headerCoverSearchInputField::placeholder {
  color: #000;
}
.headerCoverSearchInputField:focus {
  outline: none;
}
.headerCoverSearchButton {
  background-color: #f1af43;
  padding: 7px;
  border-radius: 50%;
}
.headerCoverRegisterButton {
  width: 139px;
  /* height: 36px; */
  text-align: center;
  background-color: #f1af43;
  color: #722526;
  border-radius: 20px;
  padding: 8px 0;
  /* font-family: var(--opensans-bold) !important; */
  transition: all 0.3s ease;
  cursor: pointer;
}
.headerCoverRegisterButton:hover {
  color: #f1af43;
  background-color: #722526;
}
.headerSearchButton {
  background-color: transparent;
  border: none;
  transform: translate(13px, 0px);
}
.headerSearchButton:focus {
  outline: none;
}


@media only screen and (max-width: 1024px) {
  .headerCoverHeading {
    font-size: 1.8rem;
    margin-bottom: 1rem !important;
  }
  .headerCoverDiv_overlay{
    padding-top: 30%;
  }
  .headerCoverDiv{
    min-height: 65vh;
  }
  .headerCoverLabel{
    margin-right: initial;
  }
}
@media only screen and (max-width: 767px) {
  .headerCoverDiv{
    min-height: 75vh;
  }
  .headerCoverSearchDiv{
    padding: 5px 15px;
    margin-top: 5%;
    font-size: 14px;
  }
  .headerCoverMainDiv{
    margin-bottom: 0rem;
  }
}