.itemPara {
  font-size: 12px;
  max-width: 200px;
}
.itemHeading {
  color: #000000;
}
.itemImage {
  width: 90px;
  height: 90px;
}
.itemImageDiv {
  transition: all 0.3s linear;
  background-color: #fff;
  padding: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.aboutTressItemDiv:hover .itemImageDiv {
  background-color: #be684d;
  padding: 30px;
  border-radius: 50%;
  transform: rotateY(180deg);
}
