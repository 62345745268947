@import "../constants/fonts/fonts.css";

.getStartButton {
  font-family: var(--opensans-bold) !important;
  background-color: #722526;
  color: #f1af43;
  width: 150px;
  padding: 9px 0;
  border-radius: 25px;
}
.getStartTagLine {
  font-family: var(--opensans-bold) !important;
  color: #000;
}
.recommendedProduct {
  flex: 1;
  /* border: solid 1px #ccc; */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.recommendedProduct:hover {
  box-shadow: 0px 3px 5px 0px #ccc;
  transform: translateY(-5px);
}

.headerCoverMainDiv {
  margin-bottom: 5rem;
}
.headerCoverDiv {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.headerCoverHeaderDiv {
  min-height: 60vh;
}
.headerCoverHeading {
  color: #fff;
  font-size: 45px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.headerCoverLabel {
  color: #fff;
  text-transform: uppercase;
}
.headerCoverSearchDiv {
  min-width: 70%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 3px 12px 3px 16px;
  border-radius: 30px;
  border: solid 1px #fff;
}
.headerCoverSearchInputField {
  flex: 1;
  background-color: transparent;
  border: none;
  color: #fff;
}
.headerCoverSearchInputField::placeholder {
  color: #fff;
}
.headerCoverSearchInputField:focus {
  outline: none;
}
.headerCoverSearchButton {
  background-color: #f1af43;
  padding: 7px;
  border-radius: 50%;
}
.headerCoverRegisterButton {
  width: 139px;
  height: 36px;
  text-align: center;
  background-color: #f1af43;
  color: #722526;
  border-radius: 20px;
  padding-top: 6px;
  font-family: var(--opensans-bold) !important;
  transition: all 0.3s ease;
  cursor: pointer;
}
.headerCoverRegisterButton:hover {
  color: #f1af43;
  background-color: #722526;
}
.headerSearchButton {
  background-color: transparent !important;
  border: none !important;
  transform: translate(10px, 4px) !important;
}
.headerSearchButton:focus {
  outline: none;
}
/* .theStudyImage {
  background-image: url(./theStudy.png) !important;
  width: 153px !important;
  height: 153px !important;
  background-position: inherit !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 20px !important;
} */
/* .theProsHeading {
  font-size: 30px !important;
  font-family: var(--opensans-bold) !important;
  color: #772e30 !important;
} */
.theProsPara {
  font-size: 23px !important;
  max-width: 510px !important;
  color: #000 !important;
}
.thecraftHeading {
  font-size: 20px;
  /* font-family: var(--opensans-bold) !important; */
  color: #772e30;
}
.thecraftPara {
  font-size: 23px;
  max-width: 510px;
  color: #000;
}
.theProsImage {
  max-width: 100%;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 11%);
}
.theProsHeading {
  font-size: 20px;
  /* font-family: var(--opensans-bold) !important; */
  color: #772e30;
}
.theStudyImage {
  width: 153px;
  height: 153px;
  background-position: inherit;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 11%);
}
.theStudyHeading {
  font-size: 20px;
  /* font-family: var(--opensans-bold) !important; */
  color: #772e30;
}
.theProductImage {
  width: 153px;
  height: 153px;
  background-position: inherit;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 11%);
}
.theProductHeading {
  font-size: 20px;
  /* font-family: var(--opensans-bold) !important; */
  color: #772e30;
}
.theProductPara {
  font-size: 23px;
  max-width: 510px;
  color: #000;
}
