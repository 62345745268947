.recommendedButton{
  background-color: rgb(114, 37, 38);
  border-radius: 8px;
  padding: 16px;
  margin: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 16px;
  z-index: 10;
}

.recommendedButton a{
  color: white;
}

.recommendedButton a:hover{
  color: white;
}

.recommendedButton a::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}