.howItWordksMainDiv {
  background-color: #f2f2f2;
}
.mainHeading,
.countLabel,
.howItWorksTitle {
  color: #000;
  font-weight: 900;
}
.howItWorksPara {
  font-size: 11px;
  max-width: 250px;
}
.itemImageDiv {
  transition: all 0.2s ease;
  cursor: pointer;
}
.itemImageDiv:hover {
  /* transform: rotateY(180deg); */
}
