.footerLogoText {
  font-size: 27px;
  color: #000;
  font-weight: 900;
}
.footerNavColHeading,
.footerSubscribeFormHeading {
  font-size: 22px;
  color: #000;
}
.footerMainDiv {
  /* background-color: #06154c; */
  background-color: #f2f2f2;
}
.iconDiv {
  background-color: #f1af43;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  transition: all 0.3s ease;
}
.nanLink {
  color: #722526;
  font-size: 13px;
  line-height: 1.6;
}

.nanLink:hover {
  color: #722526;
}
.footerSubscribeInput {
  padding: 10px;
  border-radius: 30px;
  border: solid 1px #000;
}
.footerSubscribeInput:focus {
  outline: none !important;
}
.footerSubscribeSubmitButton {
  background-color: #722526;
  color: #f1af43;
  font-size: 15px;
  width: 130px;
  border-radius: 20px;
  padding: 8px 2px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.footerSubscribeSubmitButton:hover {
  color: #722526;
  background-color: #f1af43;
}
.socialIcon {
  font-size: 24px;
  color: #722526;
  transition: all 0.5s ease;
}
/* .socialIcon:hover {
  color: #f1af43;
  background-color: #722526;
} */
.iconDiv:hover {
  background-color: #722526;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  cursor: pointer;
}
.iconDiv:hover .socialIcon {
  color: #f1af43;
}
.sendEmailButton {
  color: #f1af43 !important;
  font-size: 20px;
}
.sendEmailButton:hover {
  color: #722526 !important;
}
