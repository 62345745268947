@import "../../constants/fonts/fonts.css";
/* p {
  font-family: var(--opensans-light);
} */
.serviceImage {
  max-width: 100%;
}
.serviceDivOverlay {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  /* background-color: rgba(0, 0, 0, 0.7) !important; */
  background-color: rgb(114, 37, 38, 0.6);
  z-index: 1 !important;
  max-width: 540px !important;
}
.imageBox {
  position: relative;
  margin: auto;
  overflow: hidden;
  min-width: 100%;
  /* width: 540px; */
}
.imageBox img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}
.mainHeading,
.countLabel,
.howItWorksTitle {
  color: #000;
  font-weight: 900;
  text-align: center;
}
.serviceDescDiv:hover img {
  transform: scale(1.3);
}
.serviceDescDiv:hover .serviceContentDiv > h2 {
  font-size: 36px;
  /* font-weight: 600; */
  color: #fff;
}
.serviceContentDiv {
  position: absolute !important;
  max-width: 450px !important;
  z-index: 2 !important;
  color: #fff !important;
  font-family: var(--opensans-light) !important;
}
.serviceContentDiv > h2 {
  /* color: #f1af43; */
  color: #fff;
  font-weight: 600;
  transition: all 0.1s ease;
}
