@import "../constants/fonts/fonts.css";
* {
  font-family: var(--opensans-regular);
}
.loginFormMainContainer {
  background-color: #f2f2f2;
  padding: 3rem 0;
  height: 100vh;
}
.loginFormContainer,
.formSuggestionLabelDiv {
  color: #8e5051;
}
.nameFieldRow > div {
  flex: 1;
}

.loginFormContainer {
  padding: 1rem 3rem;
}
.loginInputField {
/* font-size: 35px; */
height: 54px;
}
.loginButton {
border: none;
background-color: #722526;
margin: auto;
}
.loginInputField:focus,
.loginButton:focus {
outline: none;
}
.loginFormContainer input{
border-radius: 30px;
padding: 20px 15px;
width: 100%;
height: 40px;
font-size: 14px;
border: 1px solid #d6d6d6;
}
.loginFormContainer label{
margin: 0 0 6px 0;
color: #000;  
font-size: 14px;
}
.loginFormContainer h1{
color: #000;
}




@media only screen and (min-width: 768px) {

}