.bgGrey{
    background-color: #f2f2f2;
    position: relative;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom:30px;
}
.card{
    padding: 30px 30px;
    border-radius: 10px;
    background-color: white;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    z-index: 1;
    position: relative;
}

.snowflake{
    color: #722526;
    position: absolute;
    font-size: 160px;
    opacity: .10;
    z-index: 0;
}
.snowflake1{
    left: -10px;
    top: 0px;
    color: #f1af43;
}
.snowflake2{
    left: 50%;
    top: -100px;
}
.snowflake3{
    left: 30%;
    top: 20%;
    color: #f1af43;
}
.snowflake4{
    left: 75%;
    top: 80%;
    color: #f1af43;
}
.snowflake5{
    left: 90%;
    top: 50%;
}
.snowflake6{
    left: 5%;
    top: 90%;
}
.giveAway h2{
    color:#722526;
    font-weight: 500;
    font-weight: bold;
    position: relative;
    text-transform:uppercase;
    letter-spacing: 8px;
    text-align: center;
}
.giveAwayHeader{
    margin-bottom: 90px;
    color: #f1af43 !important;
}
.giveAwayHeader:after{
    color:#f1af43;
    position: absolute;
    content: "GIVEAWAY";
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    font-weight: bold;
    letter-spacing: 8px;
    opacity: .5;
}
.giveAwayHeader:before{
    color:#f1af43;
    position: absolute;
    content: "GIVEAWAY";
    left: 50%;
    transform: translateX(-50%);
    top: 200%;
    font-weight: bold;
    letter-spacing: 8px;
    opacity: .25;
}
.giveAway ul{
    list-style: none;
    list-style-type: symbols();
}

.giveAway ul li{
    margin-bottom: 10px;
}
ul li:nth-child(odd) i{
    margin-right: .5em;
    color: #f1af43;
}

ul li:nth-child(even) i{
    margin-right: .5em;
    color: #722526;
}

.rulesTitle{
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-transform: uppercase;
    position: relative;
}
.rulesTitle::after{
    position:absolute;
    content: '';
    top: 15%;
    bottom: 15%;
    background-color: #f1ae4394;
    width: 3px;
    right: -50%;
    transform: translateX(50%);
}
.rulesList{
    position: relative;
    /* top: 50%;
    transform: translateY(-50%); */
    font-size: .9em;
}
@media only screen and (max-width: 767px) {
    .card{
        padding: 30px 32px;
        
    }
    .giveAway h2{
        color:#722526;
        text-align: center;
    }
    
  }